@import '../../css/color.scss';

.teaser-container {
  display: grid;
  grid-column-gap: 16px;
  color: $color-white;
  background-color: $color-ul-grey;

  .content-teaser {
    background-color: $color-black;
    padding: 16px 16px 64px;
    width: -webkit-fill-available;
    text-align: left;
    grid-column-gap: 16px;
    grid-column-start: span 4;
    cursor: pointer;

    img {
      width: inherit;
      height: inherit;
    }

    .eyebrow-text {
      margin-top: 26px;
    }

    p {
      overflow: auto;
    }
  }
}
