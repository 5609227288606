@import '../../../../css/color.scss';
@import '../../../../css/font.scss';

.custom-container {
  display: flex;
  max-width: 256px;
  width: 100%;
  pointer-events: auto;

  .MuiInputBase-input.MuiOutlinedInput-input {
    font-family: 'Slalom Sans';
    letter-spacing: normal;
    font-weight: 400;
    width: 100%;
    color: $color-secondary;
    pointer-events: auto;
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: 'Slalom Sans';
    letter-spacing: normal;
    font-weight: 400;
    width: 100%;
    font-size: 16px;
    color: $color-md-grey;
    transform: translate(13px, 14px);
    &.MuiInputLabel-shrink {
      transform: translate(13px, -9px);
      font-size: 12px;
    }
    &.Mui-focused {
      color: $color-secondary;
      transform: translate(13px, -9px);
      font-size: 12px;
    }
    &.Mui-disabled {
      color: $color-lt-grey;
    }
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    width: 100%;
    height: 50px;
    border-radius: 0;
    border-color: $color-lt-grey;

    &.Mui-focused:not(.blurred) {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-primary;
        font-family: 'Slalom Sans';
        legend {
          max-width: 100%;
        }
      }
      .MuiSvgIcon-root {
        transform: none;
        color: $color-primary;
      }
    }
    &.Mui-focused.blurred {
      &:hover {
        fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-black;
        }
      }
      &.hover {
        fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-black;
        }
      }
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-lt-grey;
      }
    }
    &.Mui-disabled {
      background-color: $color-ul-grey;
      border-color: $color-lt-grey;
      .MuiSvgIcon-root {
        transform: none;
        color: $color-ul-grey;
      }
    }
  }
}
