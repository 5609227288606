@use 'sass:math';
@import '../../../css/color.scss';
@import '../../../css/font.scss';

$four-thirds: math.div(4, 3);

.multi-select-form-control {
  width: 100%;
  .user-array-select.MuiOutlinedInput-root {
    height: 50px;
    border-radius: 0;
    border-color: $color-lt-grey;
    position: relative;

    &:not(.Mui-focused)legend {
      max-width: 0;
    }

    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-black;
      }
    }

    &.Mui-focused:not(.blurred) {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-primary;
        legend {
          max-width: 100%;
        }
      }
      .MuiSvgIcon-root {
        transform: none;
        path {
          @include black-to-primary;
        }
      }
    }

    &.Mui-focused.blurred {
      &:hover {
        fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-black;
        }
      }
      &.hover {
        fieldset.MuiOutlinedInput-notchedOutline {
          border: 1px solid $color-black;
        }
      }
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-lt-grey;
        legend {
          max-width: 0%;
        }
      }
    }

    &.Mui-focused.blurred.selected-items {
      fieldset.MuiOutlinedInput-notchedOutline {
        legend {
          max-width: 100%;
        }
      }
    }

    &.selected-items {
      fieldset.MuiOutlinedInput-notchedOutline legend {
        max-width: 100%;
      }
    }

    .MuiSvgIcon-root {
      margin-right: 10px;
      transform-origin: 12px 12px;
      transform: rotate(180deg);
      transition: transform 300ms ease-in;
      path {
        transform: scale(0.85);
        transform-origin: 12px 12px;
      }
    }

    .MuiSelect-select {
      padding: 13px 18px;
      display: flex;

      .box {
        display: flex;
        align-items: center;
        border-radius: 0;
        @include p;
        box-sizing: border-box;
        height: 32px;
        padding: 4px 8px;
        background-color: $color-ul-grey;
        border: 1px solid $color-lt-grey;

        &:not(:last-child) {
          margin-right: 12px;
        }

        span.MuiChip-label {
          padding: 0;
        }

        .MuiChip-deleteIcon {
          margin-left: 4px;
          margin-right: 0;
        }
      }
    }
  }

  #multi-select-label {
    @include p;
    letter-spacing: normal;

    &:not(.selected-items) {
      transform: translate(16px, 12px);
    }

    &.selected-items {
      transition: none;
      @include p-caption;
      transform: translate(12px, -9px);
    }

    &.Mui-focused:not(.blurred) {
      color: $color-secondary;
      @include p-caption;
      transform: translate(12px, -9px);
    }

    &.Mui-focused.blurred {
      color: $color-md-grey;
    }

    &.hover {
      cursor: pointer;
    }
  }
}

#menu-item {
  padding: 8px 14px;
  margin: 0 4px;

  &.Mui-focusVisible {
    background-color: $color-ul-grey;

    svg.MuiSvgIcon-root path {
      stroke: $color-ul-grey;
      stroke-width: 1px;
    }
  }

  &.Mui-selected {
    background-color: $color-white;
    &.Mui-focusVisible {
      background-color: $color-ul-grey;
    }
  }

  .MuiCheckbox-root {
    padding: 0;
    width: 24px;
    height: 24px;

    &.Mui-checked {
      border-radius: 2px;
      border: 1px solid $color-lt-grey;

      .MuiSvgIcon-root {
        path {
          transform: scale(0.6);
          fill: $color-secondary;
          stroke: $color-secondary;
          stroke-width: 2px;
        }
      }
    }

    &:hover {
      background-color: transparent;
    }

    .MuiSvgIcon-root path {
      transform-origin: center;
      transform: scale($four-thirds);
      color: $color-lt-grey;
      stroke: $color-white;
      stroke-width: 1px;
    }
  }

  &:hover {
    .MuiSvgIcon-root path {
      stroke: $color-ul-grey;
      stroke-width: 1px;
    }
  }

  .MuiListItemText-root {
    padding: 0;
    margin-left: 12px;
    .MuiListItemText-primary {
      @include p;
      color: $color-black;
    }
  }
}
div.MuiPaper-root.MuiPaper-elevation {
  max-height: 176px;
  border: 1px solid $color-lt-grey;
  border-radius: 0;
  box-shadow: 0px 2px 8px rgba(9, 9, 28, 0.06);
}
