@import '../../../css/color.scss';
@import '../../../css/font.scss';

.legal-names {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;

  .input-field {
    max-width: 256px;
    height: 50px;
    width: 100%;
  }

  .input {
    .MuiOutlinedInput-input {
      font-family: 'Slalom Sans';
      padding-left: 18px;
      padding-right: 18px;
    }

    &.has-input .MuiInputLabel-root {
      font-family: 'Slalom Sans';
      transition: none;
      transform: translate(12px, -6px) scale(0.75);
      &.Mui-focused {
        color: $color-secondary;
        transform: translate(12px, -6px) scale(0.75);
      }
    }

    &:not(.has-input) .MuiInputLabel-root {
      font-family: 'Slalom Sans';
      transform: translate(16px, 16px) scale(1);

      &.Mui-focused {
        color: $color-secondary;
        transform: translate(12px, -6px) scale(0.75);
      }

      &:not(.Mui-focused) {
        transform: translate(16px, 16px);
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 0px;
      inset: 0;
      height: 50px;
    }

    .Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-primary;
      }
    }
  }
}

.name-field {
  max-width: 256px;
  width: 100%;
}
