@import '../../../css/color.scss';
@import '../../../css/font.scss';

.email-search-container {
  display: flex;
  max-width: 360px;
}
.email-search.MuiFormControl-root {
  width: 100%;
  pointer-events: auto;
  display: flex;
  .label.MuiFormLabel-root.MuiInputLabel-root {
    @include p;
    letter-spacing: normal;
    width: 100%;
    color: $color-md-grey;
    transform: translate(13px, 14px);
    &.Mui-focused {
      color: $color-secondary;
      transform: translate(13px, -12px);
      font-size: 12px;
    }
  }
  .blurred.MuiFormLabel-root.MuiInputLabel-root {
    @include p;
    color: $color-secondary;
    transform: translate(13px, -12px);
    font-size: 12px;
    letter-spacing: normal;
  }

  .email-search-outline.MuiInputBase-root.MuiOutlinedInput-root {
    @include p;
    height: 50px;
    border-radius: 0;
    border-color: $color-lt-grey;
    letter-spacing: normal;
    width: 100%;
    color: $color-black;
    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-primary;
        font-family: 'Slalom Sans';
      }
    }
  }
  .email-searched {
    @include p;
    height: 50px;
    border-radius: 0;
    border-color: $color-lt-grey;
    letter-spacing: normal;
    width: 100%;
    color: $color-black;
    fieldset.MuiOutlinedInput-notchedOutline {
      border: none;
    }
    border: solid 1px $color-lt-grey;
  }

  .button-base.MuiButtonBase-root.MuiIconButton-root {
    padding-right: 18px;
    &.MuiButtonBase-root.MuiIconButton-root:hover {
      background-color: transparent;
    }
  }

  .search-icon.MuiSvgIcon-root {
    color: $color-md-grey;
  }
  .clear-icon.MuiSvgIcon-root {
    color: $color-md-grey;
    height: 18px;
    width: 18px;
    border: solid $color-md-grey 2px;
    border-radius: 7px;
  }
}
