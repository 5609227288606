@import '../../css/color.scss';
@import '../../css/font.scss';

.admin-dashboard {
  margin: 100px 126px;
  display: flex;
  flex-flow: wrap;
  column-gap: 80px;
  color: $color-black;
  .title {
    width: 100%;
    margin-bottom: 56px;
  }
  .worklist {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .not-found-message {
    color: $color-lt-grey;
  }
}

.filters {
  max-width: 360px;
  width: 100%;
}

.filters-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 360px;
}

.dashboard-label {
  width: 100%;
  margin-bottom: 32px;
  margin-top: 0px;
}

.filter-by-label {
  margin-top: 32px;
  margin-bottom: 16px;
}

.multi-select {
  width: 100%;
}

.search-by-label {
  margin: 16px 0px;
}
