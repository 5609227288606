@import '../../css/color.scss';
@import '../../css/font.scss';
@import '../../pages/registration-page/registration-page.scss';

.step {
  @include button-text;
  font-size: 14px;
  color: $color-md-grey;
}

.title {
  margin-top: 4px;
  margin-bottom: 32px;
  color: $color-black;
}

.name {
  margin: 0;
  color: $color-black;
}
