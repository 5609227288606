@import '../../../css/color.scss';

.login-landing {
  display: grid;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-items: center;
  align-items: center;

  .login-hero-video {
    width: 100%;
    height: 100%;
    grid-area: 1/1/2/2;
    display: grid;
    object-fit: cover;
  }

  .login-start {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 720px;
    min-height: 290px;
    padding: 48px 64px;
    box-sizing: border-box;
    grid-area: 1/1/2/2;
    z-index: 10;

    .title {
      margin: 0 0 32px 0;
    }

    .email {
      margin-bottom: 22px;
    }

    .login-button {
      width: 137px;
      height: 48px;
      margin-top: 40px;
    }
  }
}
