@import './color.scss';

@font-face {
  font-family: 'Slalom Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/SlalomSans-Regular.woff') format('woff'),
    url('../fonts/SlalomSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Slalom Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/SlalomSans-Italic.woff') format('woff'),
    url('../fonts/SlalomSans-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Slalom Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/SlalomSans-Bold.woff') format('woff'),
    url('../fonts/SlalomSans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Slalom Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/SlalomSans-BoldItalic.woff') format('woff'),
    url('../fonts/SlalomSans-BoldItalic.woff2') format('woff2');
}

@mixin defaults {
  font-family: 'Slalom Sans';
  font-style: normal;
}

@mixin button-text {
  @include defaults;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@mixin h1 {
  @include defaults;
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: 0.01em;
}

@mixin h2 {
  @include defaults;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
}

@mixin h3 {
  @include defaults;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.01em;
}

@mixin h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

@mixin p {
  @include defaults;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin p-bold {
  @include p;
  font-weight: 700;
}

@mixin p-hero {
  @include p;
  font-size: 20px;
  line-height: 28px;
}

@mixin p-caption {
  @include p;
  font-size: 12px;
  line-height: 18px;
}

@mixin p-legal {
  @include p;
  font-size: 10px;
  line-height: 18px;
}

@mixin p-eyebrow-text {
  @include p;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

h1 {
  @include defaults;
  font-weight: 700;
  font-size: 72px;
  line-height: 72px;
  letter-spacing: 0.01em;
}

h2 {
  @include defaults;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
}

h3 {
  @include defaults;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.01em;
}

h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

p {
  @include defaults;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  &.bold {
    font-weight: 700;
  }

  &.hero {
    font-size: 20px;
    line-height: 28px;
  }

  &.caption {
    font-size: 12px;
    line-height: 18px;
  }

  &.legal {
    font-size: 10px;
    line-height: 18px;
  }

  &.eyebrow-text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }

  &.registration-step {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $color-md-grey;
  }
}
