@import '../../css/color.scss';

.FeaturesSection {
  .Header {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      text-align: center;
      color: $color-secondary;
      max-width: 814px;
      margin: 109px 30px 92px 30px;
    }
  }

  .Feature-Card-Container {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 140px;
  }
}
