@import '../../css/color.scss';
@import '../../css/font.scss';

.event-card {
  width: 396px;
  box-shadow: 0px 58px 23px rgba(0, 0, 0, 0.01),
    0px 33px 20px rgba(0, 0, 0, 0.02), 0px 14px 14px rgba(0, 0, 0, 0.03),
    0px 4px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  background-color: $color-white;

  &:hover {
    cursor: pointer;
  }

  .event-picture-container {
    position: relative;
    height: 240px;
    .location-box {
      width: fit-content;
      background-color: $color-ul-grey;
      border: $color-lt-grey 1px solid;
      @include p;
      padding: 4px 8px;
      position: absolute;
      z-index: 9;
      transform: translate(16px, 16px);
    }
    .event-picture {
      position: relative;
      border-radius: 12px 12px 0 0;
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
    &:before {
      @include color-overlay($color-black, 0.1, 8);
      border-radius: 12px 12px 0 0;
    }
    &:after {
      @include color-overlay($color-primary, 0.2, 8);
      border-radius: 12px 12px 0 0;
    }
  }

  .event-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 37px 24px;

    .event-time-details {
      color: $color-secondary;
      text-align: center;
      h4 {
        margin-top: 0;
        margin-bottom: 4px;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        margin: 0;
      }
    }
    .event-text-details {
      margin-left: 24px;
      flex-shrink: 2400;
      p {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
}
