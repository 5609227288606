@import '../../css/color.scss';
@import '../../css/font.scss';

@mixin hamburger-menu {
  .hamburgerMenu {
    display: block;
    cursor: pointer;
    color: $color-white;
    height: 36px;
    width: 36px;
  }

  .navigationMenu.open {
    display: flex;
    justify-content: flex-end;
  }

  .navigationList {
    display: none;
  }
}

@mixin navigation-menu-open {
  display: block;
  list-style-type: none;
  align-items: left;
  position: absolute;
  margin: 0;
  flex-direction: column;
  max-width: 100%;
  right: -0.2px;
  background-color: $color-black;
  z-index: 15;
}

.header {
  background: $color-black;
  width: 100%;
  position: relative;

  .innerHeaderContainer {
    max-width: 1500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    margin: 0 auto;

    .logo {
      cursor: pointer;
      width: 156px;
    }

    .hamburgerMenu {
      display: none;
    }

    .navigationList {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
      align-items: center;

      .loginButtonItem {
        margin-left: 32px;
      }

      .loginTextItem {
        display: none;
      }

      .loggedInHamburgerLinks {
        display: none;
      }

      .loggedInHeaderLinks {
        display: flex;

        .alumNavbar {
          display: flex;
          gap: 30px;

          .careersAndReferrals {
            margin-right: 16px;
          }
        }

        .alumLinksContainer {
          display: flex;
          flex-direction: row;
          color: $color-white;
          position: relative;

          .dropdown-fullbar {
            display: flex;
            height: 0px;
            overflow: hidden;
            flex-direction: column;
            justify-content: space-evenly;
            width: 210px;
            background-color: $color-black;
            position: absolute;
            top: 40px;
            right: -32px;
            transition: height 0.25s ease-in;

            &.open {
              width: 210px;
              height: 184px;
            }

            &.blurred.hover {
              width: 210px;
            }
            .solid-button.text-only {
              margin-left: 42px;
              margin-bottom: 24px;
            }

            .myProfile {
              margin-top: 32px;
            }
          }

          .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.profileImage {
            background-color: $color-white;
            margin-left: 16px;
            margin-right: 4px;
            cursor: pointer;

            .MuiSvgIcon-root {
              color: $color-black;
            }
          }

          .navigation-fullbar {
            display: flex;
            cursor: pointer;

            .MuiSvgIcon-root {
              margin-left: 11.25px;
            }

            &.open {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  // breakpoint behaviour for default header
  &.default {
    @media screen and (max-width: 572px) {
      .hamburgerMenu {
        display: block;
        cursor: pointer;
        color: $color-white;
        height: 36px;
        width: 36px;
      }

      .navigationMenu.open {
        display: flex;
        justify-content: flex-end;
      }

      .navigationList {
        display: none;
      }

      .loggedInHeaderLinks {
        display: flex;

        .alumNavbar {
          display: flex;
          gap: 30px;

          .careersAndReferrals {
            margin-right: 16px;
          }

          .bold {
            @include p-bold;
          }
        }

        .alumLinksContainer {
          display: flex;
          flex-direction: row;
          color: $color-white;
          position: relative;

          .dropdown-fullbar {
            display: flex;
            height: 0px;
            overflow: hidden;
            flex-direction: column;
            justify-content: space-evenly;
            width: 210px;
            background-color: $color-black;
            position: absolute;
            top: 40px;
            right: -32px;
            transition: height 0.25s ease-in;

            &.open {
              width: 210px;
              height: 184px;
            }

            &.blurred.hover {
              width: 210px;
            }
            .solid-button.text-only {
              margin-left: 42px;
              margin-bottom: 24px;
            }

            .myProfile {
              margin-top: 32px;
            }
          }

          .MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.profileImage {
            background-color: $color-white;
            margin-left: 16px;
            margin-right: 4px;
            cursor: pointer;

            .MuiSvgIcon-root {
              color: $color-black;
            }
          }

          .navigation-fullbar {
            display: flex;
            cursor: pointer;

            .MuiSvgIcon-root {
              margin-left: 11.25px;
            }

            &.open {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }

  // breakpoint behaviour for default header
  &.default {
    @media screen and (max-width: 572px) {
      @include hamburger-menu();

      .navigationMenu.open ul {
        @include navigation-menu-open();

        .loginTextItem {
          display: flex;
          margin: 24px 32px;
        }
      }

      .registerLinkItem {
        margin: 24px 32px;
      }

      .loginButtonItem {
        display: none;
      }
    }
  }

  // breakpoint behaviour for logged In header
  &.isLoggedIn {
    @media screen and (max-width: 1020px) {
      @include hamburger-menu();

      .navigationMenu.open ul {
        @include navigation-menu-open();

        .loggedInHeaderLinks {
          display: flex;
          flex-direction: column;
          margin: 24px 32px;
        }

        .loggedInHamburgerLinks {
          display: flex;
          flex-direction: column;

          .solid-button.text-only {
            margin-bottom: 24px;
            margin-left: 32px;
            margin-right: 32px;
          }
        }
      }
    }
  }
  &.isAdmin {
    .bar-item:not(:last-child) {
      margin-right: 30px;
    }
    .logout-solid {
      display: none;
    }
    @media screen and (max-width: 800px) {
      @include hamburger-menu();

      .navigationMenu.open ul {
        @include navigation-menu-open();

        .logout-solid {
          display: flex;
          flex-direction: column;
          margin: 24px 32px;
        }

        .bar-item {
          margin: 24px 32px;
        }

        .logout {
          display: none;
        }
      }
    }
  }
}
