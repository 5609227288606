@import '../../../css/color.scss';

.iconContainer {
  background: $color-ul-grey;
  padding: 16px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  max-width: 25px;
}

.primaryIcon {
  color: $color-primary;
}
