@import '../../css/color.scss';
@import '../../pages/registration-page/registration-page.scss';
@import '../../css/font.scss';

.info-box {
  .registration-step {
    margin: auto;
    margin-bottom: 8px;
  }

  .title {
    margin: auto;
    margin-bottom: 32px;
    color: $color-black;
  }

  .button-container.profile-box {
    justify-content: space-between;
    .left-buttons {
      display: flex;
      gap: 12px;
    }
    .skip-button {
      align-self: flex-end;
      border: none;
      @include button-text;
      display: flex;
      font-weight: 400;
      padding: 0;
      color: $color-md-grey;
      background-color: transparent;
      transition: text-decoration-line 0.3s ease;

      &:hover {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }
}
