@import '../../css/color.scss';

.user-card {
  box-shadow: 0px 58px 23px rgba(0, 0, 0, 0.01),
    0px 33px 20px rgba(0, 0, 0, 0.02), 0px 0px 14px rgba(0, 0, 0, 0.03),
    0px 0px 8px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 20px 32px;
  margin-bottom: 16px;
  width: -webkit-fill-available;
  .basic-user-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  p {
    margin: 0;
  }
  .more-user-info {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 24px;

    .capability {
      margin-bottom: 4px;
    }
  }
  .buttons {
    display: flex;
    column-gap: 12px;
  }
  .dropdown {
    transform: rotate(-180deg);
  }
  .hover {
    cursor: pointer;
    &:focus {
      border: none;
      outline: none;
    }
  }
}
