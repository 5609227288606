@import '../../css/color.scss';

.event-grid {
  padding: 6% 8% 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color-ul-grey;

  .title-bar {
    max-width: 1248px;
    width: 100%;
    display: flex;
    margin-bottom: 48px;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin: 0;
    }
    .button-container {
      display: flex;
      .button {
        background-color: $color-white;
        margin-left: 12px;
        &:hover {
          background-color: $color-black;
        }
      }
    }
  }
  .events-list {
    max-width: 1248px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 48px;
    column-gap: 30px;
    &:after {
      content: '';
      width: 396px;
    }
  }

  @media screen and (max-width: 1485px) {
    .title-bar {
      max-width: 822px;
    }
    .events-list {
      justify-content: center;
    }
  }
  @media screen and (max-width: 978px) {
    .title-bar {
      width: 396px;
    }
  }
}
