@import '../../css/color.scss';
@import '../../css/font.scss';

.drop-down-form.MuiFormControl-root.MuiFormControl-fullWidth {
  margin-bottom: 16px;
  display: flex;
}

.select.MuiOutlinedInput-root {
  display: flex;
  max-width: 256px;
  height: 50px;
  border-radius: 0;
  border-color: $color-lt-grey;
  position: relative;

  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $color-black;
    }
  }

  &.Mui-focused:not(.blurred) {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $color-primary;
      font-family: 'Slalom Sans';
      legend {
        max-width: 100%;
      }
    }
    .MuiSvgIcon-root {
      transform: none;
      color: $color-primary;
    }
  }

  &.Mui-focused.blurred {
    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
    &.hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid $color-lt-grey;
    }
  }

  .MuiSvgIcon-root {
    margin-right: 18px;
    transform-origin: 12px 12px;
    transform: rotate(180deg);
    transition: transform 300ms ease-in;

    path {
      transform: scale(0.85);
      transform-origin: 12px 12px;
    }
  }

  .MuiSelect-select {
    padding: 13px 18px;
    display: flex;
  }
}

.label.MuiFormLabel-root.MuiInputLabel-root {
  font-family: 'Slalom Sans';
  letter-spacing: normal;
  font-weight: 400;
  width: auto;
  color: $color-md-grey;

  &:not(.selected) {
    transform: translate(16px, 12px);
  }
  &.selected {
    font-size: 12px;
    transform: translate(13px, -9px);
    transition: none;
  }

  &.Mui-focused:not(.blurred) {
    color: $color-secondary;
    font-family: 'Slalom Sans';
    font-weight: 400;
    transform: translate(13px, -9px);
    font-size: 12px;
  }
  &.Mui-focused.blurred {
    color: $color-md-grey;
  }
  &:hover {
    cursor: pointer;
  }
  &.Mui-disabled {
    color: $color-lt-grey;
  }

  .MuiFormControl-root {
    display: flex;
    width: 100%;
  }
}

.dropdown-select.MuiInputBase-root.MuiOutlinedInput-root {
  display: flex;
  font-family: 'Slalom Sans';
  max-width: 256px;
  height: 50px;
  border-width: 1px;
  border-radius: 0;
  border-color: $color-lt-grey;
  align-items: center;
  &.Mui-focused.blurred.selected {
    fieldset.MuiOutlinedInput-notchedOutline {
      legend {
        max-width: 100%;
      }
    }
  }
  &.selected {
    fieldset.MuiOutlinedInput-notchedOutline legend {
      max-width: 100%;
    }
  }
  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid $color-black;
    }
  }

  &.Mui-focused:not(.blurred) {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $color-primary;
      font-family: 'Slalom Sans';
      legend {
        max-width: 100%;
        transform: translate(13px, -9px);
        transition: none;
      }
    }
    .MuiSvgIcon-root {
      transform: none;
      color: $color-primary;
    }
  }

  &.Mui-focused.blurred {
    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
    &.hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid $color-lt-grey;
      legend {
        max-width: 0%;
      }
    }
  }

  &.Mui-disabled {
    background-color: $color-ul-grey;
    pointer-events: none;
  }

  .MuiSvgIcon-root {
    color: $color-md-grey;
    margin-right: 10px;
    path {
      transform: scale(0.85);
      transform-origin: 12px 12px;
    }
    transform-origin: 12px 12px;
    transform: rotate(180deg);
    transition: transform 300ms ease-in;
  }
  .MuiSelect-select {
    padding: 13px 0px 13px 18px;
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  &.has-value {
    transition: none;
  }
}

.item.MuiMenuItem-root {
  color: $color-black;
  font-family: 'Slalom Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  padding: 13px 18px;

  &.Mui-focusVisible {
    background-color: white;
    &:hover {
      background-color: $color-ul-grey;
    }
  }
}
