@use 'sass:math';
@import '../../css/color.scss';
@import '../../css/font.scss';
@import '../../pages/registration-page/registration-page.scss';

.multi-select-container {
  display: flex;
  margin-bottom: 16px;
  .market-interest-select {
    width: 256px;
  }
  .info-icon {
    margin-left: 18px;
    @include black-to-dk-grey;
  }
}

.page-number {
  @include button-text;
  color: $color-md-grey;
  font-size: 14px;
}

.header-text {
  color: $color-black;
  .title {
    margin: 8px 0px 16px 0px;
  }

  .description {
    margin-bottom: 32px;
  }
}

.container {
  row-gap: 16px;
  margin-top: 16px;
  margin-bottom: 32px;

  .large-input-field {
    width: 416px;
    height: 50px;
  }
}

.input-field {
  max-width: 256px;
  height: 50px;
  width: 100%;
}

.input {
  .MuiOutlinedInput-input {
    font-family: 'Slalom Sans';
    padding-left: 18px;
    padding-right: 18px;
  }

  &.has-input .MuiInputLabel-root {
    font-family: 'Slalom Sans';
    transition: none;
    transform: translate(12px, -6px) scale(0.75);
    &.Mui-focused {
      color: $color-secondary;
      transform: translate(12px, -6px) scale(0.75);
    }
  }

  &:not(.has-input) .MuiInputLabel-root {
    font-family: 'Slalom Sans';
    transform: translate(16px, 16px) scale(1);

    &.Mui-focused {
      color: $color-secondary;
      transform: translate(12px, -6px) scale(0.75);
    }

    &:not(.Mui-focused) {
      transform: translate(16px, 16px);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0px;
    inset: 0;
    height: 50px;
  }

  .Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $color-primary;
    }
  }
}

.acknowledgement-form {
  margin-bottom: 32px;
}

.acknowledgement-container {
  position: relative;

  &.MuiFormControlLabel-root {
    display: table;
    margin: 0;

    .MuiFormControlLabel-label {
      font-family: 'Slalom Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $color-black;
    }
  }

  .checkbox {
    display: table-cell;
    padding-right: 12px;

    .MuiButtonBase-root.MuiCheckbox-root {
      padding: 0;
      width: 24px;
      height: 24px;

      &.Mui-checked {
        border-radius: 2px;
        border: 1px solid $color-lt-grey;

        .MuiSvgIcon-root {
          path {
            transform: scale(0.6);
            fill: $color-secondary;
            stroke: $color-secondary;
            stroke-width: 2px;
          }
        }
      }

      &:hover {
        background-color: transparent;
      }

      .MuiSvgIcon-root path {
        transform-origin: center;
        transform: scale(133%);
        color: $color-lt-grey;
        stroke: $color-white;
        stroke-width: 1px;
      }
    }
  }
}
