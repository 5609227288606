@import '../../css/color.scss';

.hero-experience {
  background: linear-gradient(0deg, rgba(9, 9, 28, 0.8), rgba(9, 9, 28, 0.8)),
    url('../../../public/hero-experience.png');
  background-position: center;
  background-size: cover;
  color: $color-white;
  padding: 60px 126px 98px 126px;

  .welcome {
    margin: 0;
  }
  .description {
    margin-top: 14px;
    max-width: 609px;
  }
}
