@import '../../css/color.scss';
.admin-dashboard {
  margin: 100px 126px;
  display: flex;
  flex-flow: wrap;
  column-gap: 80px;
  color: $color-black;
  .title {
    width: 100%;
    margin-bottom: 56px;
  }
  .worklist {
    flex: 1;
  }
}
