@import '../../css/color.scss';
@import '../../css/font.scss';

.featured-story {
  background-color: $color-black;
  position: relative;
  display: flex;

  .featured-story-text-container {
    display: flex;
    flex-direction: column;
    padding: 4% 30px 4% 9%;
    justify-content: space-between;
    width: 50%;
    box-sizing: border-box;
    .featured-story-text {
      margin-bottom: 33%;

      h4 {
        margin: 0;

        &.featured {
          color: $color-white;

          .stories {
            color: $color-primary;
            @include h4;
          }
        }
      }
    }

    .featured-story-title {
      justify-content: center;
      display: flex;
      flex-direction: column;
      max-width: 609px;
      margin-bottom: 33%;

      &:hover {
        cursor: pointer;
      }

      h2 {
        margin: 0;

        &.title-main {
          color: $color-primary;
        }

        &.title-last-word {
          color: $color-white;
        }
      }
    }
  }

  .featured-story-image-container {
    position: relative;
    width: 50%;
    flex-grow: 1;
    box-sizing: border-box;

    .featured-story-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:before {
      @include color-overlay($color-black, 0.3, 9);
    }
  }
}
