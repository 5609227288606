@import '../../css/color.scss';
@import '../../css/font.scss';

.employment-info-form.MuiFormControl-root {
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;
  margin: 0px;

  &[id='Your Current Employer'],
  &[id='Your Current Position'],
  &[id='LinkedIn Profile URL'],
  &[id='Website'] {
    height: 50px;
  }

  &#Bio {
    height: 190px;
    padding-bottom: 40px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 0px;
    width: 416px;
    height: 100%;
    align-items: flex-start;

    &.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-primary;
      }
    }

    &:hover:not(.Mui-focused) {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
  }

  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: 'Slalom Sans';
    width: 100%;

    .MuiOutlinedInput-input {
      font-family: 'Slalom Sans';
    }

    &.Mui-focused {
      color: $color-secondary;
      transform: translate(12px, -8px) scale(0.75);
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .MuiOutlinedInput-input {
    font-family: 'Slalom Sans';
    overflow: hidden;
  }

  .switch-label {
    margin-left: 20px;
    margin-top: 15px;
    height: 24px;
    align-self: flex-start;

    .switch {
      padding: 0px;
      transform: translateY(7px);

      .MuiSwitch-switchBase {
        transform: translate(2px, 2px);
        width: 20px;
        height: 20px;
        background-color: $color-white;

        &.Mui-checked {
          color: $color-white;
          transform: translate(26px, 2px);
          transition-duration: 0s;

          + .MuiSwitch-track {
            background-color: $color-secondary;
          }
        }

        .MuiSwitch-thumb {
          box-shadow: none;
        }
      }

      .MuiSwitch-track {
        width: 48px;
        height: 24px;
        border-radius: 12px;
        background-color: $color-lt-grey;
        opacity: 1;
      }
    }
    .MuiTypography-root {
      font-family: 'Slalom Sans';
      font-size: 16px;
      line-height: 24px;
    }
  }
}
