@import '../../css/color.scss';
@import '../../css/font.scss';

.featured-stories-carousel {
  width: 100%;
  height: 100%;
  background-color: $color-black;

  .indicator-icon-container {
    margin-top: 0;
    position: absolute;
    bottom: 32px;
    z-index: 9;
    .indicator-icon {
      width: 48px;
      height: 8px;
      border-radius: 4px;
      background-color: $color-lt-grey;
      &:not(:last-child) {
        margin-right: 16px;
      }
      &.indicator-icon-active {
        background-color: $color-primary;
      }
    }
  }
}
