@import '../../css/color.scss';

.profile-summary {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  column-gap: 28px;
  .profile-image {
    cursor: pointer;
    .iconContainer {
      background-color: $color-lt-grey;
    }
  }
  .profile-info {
    color: $color-black;
    .fullName {
      margin: auto;
      margin-top: 25px;
      margin-bottom: 8px;
    }
    .tagline {
      margin: auto;
      margin-bottom: 4px;
    }
    .capability {
      margin: auto;
    }
  }
}
