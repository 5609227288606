@import '../../css/color.scss';
@import '../../css/font.scss';

.videos-list-container {
  display: flex;
  column-gap: 30px;
  margin-top: 100px;

  .videos-list-item {
    display: flex;
    flex-direction: column;

    .video-list-date {
      @include p-eyebrow-text;
      padding: 16px 0px 8px 0px;
    }

    .video-list-title {
      margin: 0px;
      padding-bottom: 5%;
      width: 26.6vw;
    }

    .video-list-image-container {
      position: relative;
      cursor: pointer;
      width: 26.6vw;
      max-width: 396px;
      min-width: 263px;

      &:before {
        @include color-overlay($color-black, 0.2, 11);
      }
    }

    .video-list-playbutton {
      position: absolute;
      width: 40px;
      height: 40px;
      top: 40%;
      left: 45%;
      color: $color-lt-grey;
    }

    .video-list-timestamp {
      color: $color-white;
      position: absolute;
      @include p-caption;
      background-color: $color-secondary;
      padding: 0px 4px;
      width: 25px;
      height: 18px;
      right: 5%;
      bottom: 8%;
    }

    .video-list-playbutton,
    .video-list-timestamp {
      z-index: 10;
    }

    .video-list-image {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

@media screen and (max-width: 1050px) {
  .videos-list-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    margin-top: 100px;
    justify-content: center;
    &:after {
      content: '';
      width: 263px;
    }

    .videos-list-item {
      .video-list-title {
        padding-bottom: 32px;
      }
    }
  }
}

@media screen and (max-width: 668px) {
  .videos-list-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    margin-top: 100px;
    max-width: 668px;
  }
}
