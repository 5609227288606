@import '../../../css/color.scss';
@import '../../../pages/registration-page/registration-page.scss';

.login-info {
  .info-box {
    .title {
      margin-bottom: 32px;
    }

    .resetPassword {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .password-tooltip {
      margin-top: 16px;
    }

    .info-icon {
      margin-left: 15px;
      filter: invert(25%) sepia(13%) saturate(0%) hue-rotate(251deg)
        brightness(99%) contrast(84%);
    }

    .done-button {
      margin-top: 40px;
    }
  }
}
