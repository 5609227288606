@import '../../../css/color.scss';
@import '../../../css/font.scss';

.solid-button {
  border: none;
  @include button-text;
  display: flex;

  &.primary {
    padding: 12px 29px;
    color: $color-black;
    background-color: $color-primary;
    transition: background-color 0.3s ease;

    &:hover:not(.white-background) {
      background-color: $color-white;
    }

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      background-color: $color-ul-grey;
      color: $color-lt-grey;

      .right-icon {
        @include black-to-lt-grey;
      }
    }
  }

  &.text-only {
    font-weight: 400;
    padding: 0;
    color: $color-white;
    background-color: transparent;
    transition: text-decoration-line 0.3s ease;

    &.bold {
      font-weight: 700;
    }

    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }

    &:disabled {
      color: $color-md-grey;

      .right-icon {
        @include black-to-md-grey;
      }
    }
  }

  &.icon-only {
    padding: 8px;

    .icon-box {
      width: 32px;
      height: 32px;
      display: grid;
      justify-content: center;
      align-items: center;

      .right-icon {
        margin: 0;
      }
    }
  }

  .right-icon {
    margin-left: 12px;
    height: 12px;
  }

  &:disabled {
    pointer-events: none;
  }
}
