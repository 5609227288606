// primary and secondary brand colors
$color-primary: #1be1f2;
$color-secondary: #122f3d;

// core  black and white
$color-black: #09091c;
$color-white: #ffffff;

// greys
$color-dk-grey: #4c4c4c;
$color-md-grey: #616161;
$color-lt-grey: #cccccc;
$color-ul-grey: #f2f2f2;

// states
$color-error: #9b0000;
$color-success: #009b63;
$color-alert: #cb7c1f;

@mixin black-to-md-grey {
  filter: invert(36%) sepia(55%) saturate(0%) hue-rotate(160deg) brightness(95%)
    contrast(98%);
}

@mixin black-to-lt-grey {
  filter: invert(99%) sepia(1%) saturate(7449%) hue-rotate(181deg)
    brightness(113%) contrast(60%);
}

@mixin black-to-dk-grey {
  filter: invert(25%) sepia(24%) saturate(0%) hue-rotate(243deg)
    brightness(100%) contrast(84%);
}

@mixin black-to-primary {
  filter: invert(61%) sepia(100%) saturate(661%) hue-rotate(144deg)
    brightness(107%) contrast(90%);
}

@mixin color-overlay($color, $transparency, $z-index) {
  content: '';
  position: absolute;
  background-color: rgba($color, $transparency);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index;
}
