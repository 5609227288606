@import '../../css/color.scss';

.registration-complete {
  display: grid;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-items: center;
  align-items: center;

  .registration-hero-video {
    width: 100%;
    height: 100%;
    grid-area: 1/1/2/2;
    display: grid;
    object-fit: cover;
  }

  .registration-complete-message {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 720px;
    min-height: 290px;
    padding: 48px 64px;
    box-sizing: border-box;
    grid-area: 1/1/2/2;
    z-index: 10;

    h3 {
      margin: 0 0 16px 0;
    }
    .return-home-button {
      width: fit-content;
      margin-top: 16px;
    }
  }
}
