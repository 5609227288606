@import '../../../css/color.scss';
@import '../../../css/font.scss';

.form-label {
  margin: 40px 0px 0px;
}

.radio-group.MuiFormGroup-root {
  justify-content: center;
  align-items: center;

  .MuiFormControlLabel-root {
    pointer-events: none;
    width: 100%;
    margin-right: 0px;
    &.MuiFormControlLabel-labelPlacementEnd {
      margin-right: 0px;
    }
  }

  .dropdown-container {
    display: flex;
    width: 100%;
    gap: 12px;
    .dropdown {
      max-width: 256px;
      pointer-events: auto;
      &.disabled {
        cursor: default;
      }
    }
  }

  .not-disclosed.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    width: 100%;
    display: flex;
    margin-right: 0px;
    height: 24px;
  }

  .MuiFormControlLabel-label.MuiTypography-root {
    display: flex;
    width: 100%;
    max-height: 50px;
    font-family: 'Slalom Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-black;
    pointer-events: none;
  }

  .unchecked {
    color: $color-md-grey;
    margin: 0px;
    pointer-events: auto;
  }

  .checked {
    color: $color-black;
    margin: 0px;
    pointer-events: auto;
  }

  span.MuiButtonBase-root.MuiRadio-root {
    padding-right: 6px;
    padding-left: 3px;
    pointer-events: auto;

    &:hover {
      background: none;
    }

    .MuiSvgIcon-root {
      width: 28px;
      height: 28px;

      &:first-child {
        color: $color-lt-grey;

        path {
          stroke: $color-white;
        }
      }
    }
  }

  .MuiButtonBase-root.MuiRadio-root.Mui-checked {
    color: $color-secondary;
  }

  .options-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    margin: 16px 0px 40px;
    align-items: center;
  }

  .pronoun-selection-container {
    display: flex;
    max-width: 580px;
    max-height: 196px;
    flex-direction: column;
  }

  .slash-container {
    height: 24px;
    width: 8px;
  }
}
