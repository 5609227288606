// Breakpoint layout (for responsiveness)
$xs-phone: '(max-width: 599px)';
$s-tablet: '(min-width: 600px) and (max-width: 904px)';
$sm-tablet: '(min-width: 905px) and (max-width: 1239px)';
$md-laptop: '(min-width: 1240px) and (max-width: 1439px)';
$lg-desktop: '(min-width: 1440px)';

// extra-small (phone): 0-599dp
@media #{$xs-phone} {
  .teaser-container {
    padding: 16px;
    grid-template-columns: repeat(4, 1fr);

    .content-teaser {
      margin: 16px auto;
    }
  }

  .FeatureCardContainer {
    .title {
      font-size: 20px;
    }

    .icon {
      padding: 10px;
    }
  }

  .FeaturesSection {
    .Header {
      h2 {
        font-size: 38px;
      }
    }
    .Feature-Card-Container {
      margin: 20px 0px;
      flex-direction: column;
      align-items: center;
    }
  }
}

// small (tablet): 600-904dp
@media #{$s-tablet} {
  .teaser-container {
    padding: 32px;
    grid-template-columns: repeat(8, 1fr);

    .content-teaser {
      margin: 16px auto;
    }
  }

  .FeaturesSection {
    .FeatureCardContainer {
      // width: 333px;
      padding: 20px;

      .text {
        margin-bottom: 16px;
      }
    }
    .Header {
      h2 {
        font-size: 40px;
      }
    }

    .Feature-Card-Container {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media #{$sm-tablet} {
  .teaser-container {
    padding: 32px;
    grid-template-columns: repeat(12, 1fr);

    .content-teaser {
      margin: 16px auto;
    }
  }

  .FeaturesSection {
    .FeatureCardContainer {
      width: 333px;
      padding: 20px;
    }
    .Header {
      h2 {
        font-size: 40px;
      }
    }

    .Feature-Card-Container {
      flex-direction: column;
      align-items: center;
    }
  }
}

// medium (laptop)
@media #{$md-laptop} {
  .teaser-container {
    padding: 152px 126px;
    grid-template-columns: repeat(12, 1fr);
  }
}

// large(desktop)
@media #{$lg-desktop} {
  .teaser-container {
    padding: 152px 126px;
    grid-template-columns: repeat(12, 1fr);
  }

  .FeaturesSection {
    .Feature-Card-Container {
      flex-direction: row;
    }
  }
}
