@import '../../css/color.scss';

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(0deg, rgba(9, 8, 27, 0.72), rgba(9, 8, 27, 0.72)),
    url('../../../public/jj-ying-hero_bg.jpg');
  background-position: center;
  background-size: cover;
  background-blend-mode: darken;
  max-height: 552px;
  color: $color-white;
  width: 100%;

  .hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10%;
    padding-top: 6%;
    padding-bottom: 8%;
    padding-right: 5%;

    .welcome {
      text-align: left;
      margin: 0px 0px 30px 0px;
      letter-spacing: 0.01em;

      .exponent {
        font-size: 30px;
      }
    }

    .description {
      text-align: left;
      max-width: 518px;
      margin: 0px 0px 35px 0px;
    }

    .hero-cta {
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .invite {
      margin-right: 16px;
      margin-bottom: 10px;
    }
  }
}
