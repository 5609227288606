@import '../../css/color.scss';

.registration-base {
  background-color: $color-ul-grey;
  width: 100%;
  height: 100%;
}

.registration-info {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 10% 0%;

  .info-box {
    background-color: $color-white;
    padding: 40px 64px;
    min-height: 290px;
    max-width: 720px;
    width: 100%;
    box-sizing: border-box;

    .button-container {
      display: flex;
      gap: 12px;
    }
  }
}
