@import '../../css/color.scss';
@import '../../css/font.scss';

.article-preview {
  padding: 60px 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;

  .small-screen {
    padding-bottom: 30px;
  }

  .article-image-container {
    .article-image {
      max-width: 609px;
      width: 100%;
      max-height: 456px;
    }
  }

  .article-preview-details {
    .publish-date-container {
      display: flex;

      .rectangle-icon {
        margin-right: 8px;
      }

      .publish-date {
        color: $color-black;
        margin: 0;
      }
    }

    .article-title {
      max-width: 609px;
      width: 100%;
      height: auto;
      color: $color-black;
      margin-top: 8px;
    }

    .grid-col4 {
      grid-column: span 4;
    }

    .article {
      margin: 0 auto;
      max-width: 396px;
      width: 65%;

      .preview-paragraph {
        height: auto;
        color: $color-black;
      }

      .find-out-more {
        display: flex;
        margin-top: 16px;
        .polygon-icon {
          margin-right: 8px;
        }

        .more-button {
          @include p-eyebrow-text;
          font-weight: 700;
          color: $color-black;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .grid-col6 {
    grid-column: span 6;
  }
}

@media only screen and (max-width: 1200px) {
  .grid-colsm12 {
    grid-column: span 12;
  }
}
