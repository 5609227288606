@import '../../css/color.scss';
@import '../../pages/registration-page/registration-page.scss';

.registration-sett-step {
  margin: auto;
  font-size: 14px;
  color: $color-md-grey;
  margin-bottom: 8px;
}

.reg-info-title {
  margin: auto;
  margin-bottom: 32px;
  color: $color-black;
}

.body-text {
  margin: auto;
  margin-bottom: 32px;
  color: $color-black;
}

.checkboxes {
  display: table-cell;
  margin: auto;

  .MuiTypography-root {
    font-family: 'Slalom Sans';
    margin-left: 12px;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 32px;
    margin-left: 0;
  }

  .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0;
    width: 24px;
    height: 24px;

    &.Mui-checked {
      border-radius: 2px;
      border: 1px solid $color-lt-grey;

      .MuiSvgIcon-root {
        path {
          transform: scale(0.6);
          fill: $color-secondary;
          stroke: $color-secondary;
          stroke-width: 2px;
        }
      }
    }

    &:hover {
      background-color: transparent;
    }

    .MuiSvgIcon-root path {
      transform-origin: center;
      transform: scale(133%);
      color: $color-lt-grey;
      stroke: $color-white;
      stroke-width: 1px;
    }
  }
}

.linked-label {
  a {
    text-decoration-color: $color-black;
    color: $color-black;
  }
  .exponent {
    font-size: large;
    vertical-align: baseline;
  }
}
