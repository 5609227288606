@import '../../css/color.scss';
@import '../../css/font.scss';

.password-container {
  display: flex;

  .form.MuiFormControl-root {
    width: 528px;

    .label.MuiFormLabel-root.MuiInputLabel-root {
      font-family: 'Slalom Sans';
      letter-spacing: normal;
      font-weight: 400;
      color: $color-md-grey;
      height: 24px;
      padding: 0 4px;
      transform: translate(9px, 14px);
      &.Mui-focused {
        color: $color-secondary;
        transform: translate(9px, -9px);
        font-size: 12px;
        background-color: white;
      }
    }

    .blurred.MuiFormLabel-root.MuiInputLabel-root {
      color: $color-secondary;
      transform: translate(9px, -9px);
      font-size: 12px;
      font-family: 'Slalom Sans';
      letter-spacing: normal;
      font-weight: 400;
      padding: 0 4px;
      background-color: white;
    }

    .error {
      &.MuiFormLabel-root.MuiInputLabel-root {
        color: $color-error;
        background-color: white;
      }
    }

    .error.MuiOutlinedInput-notchedOutline {
      border: 2px solid $color-error;
    }

    .no-input.MuiInputBase-root.MuiOutlinedInput-root {
      width: 100%;
      height: 50px;
      border-radius: 0;
      border-color: $color-lt-grey;
      font-family: 'Slalom Sans';
      letter-spacing: normal;
      font-weight: 400;
      width: 100%;
      color: $color-black;

      &.Mui-focused {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: $color-primary;
          font-family: 'Slalom Sans';
        }
      }
    }

    .has-input {
      width: 100%;
      font-family: 'Slalom Sans';
      letter-spacing: normal;
      color: $color-black;
      font-weight: 400;
      height: 50px;
      border-radius: 0;
      translate: none;

      &.Mui-focused {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: $color-primary;
          border: solid 2px $color-primary;
          font-family: 'Slalom Sans';
        }
      }

      fieldset.MuiOutlinedInput-notchedOutline {
        border: 2px $color-primary;
      }

      &:not(.Mui-focused) {
        border: solid 1px $color-lt-grey;
        &.error {
          border: none;
        }
      }

      &.error fieldset.MuiOutlinedInput-notchedOutline {
        border: solid 2px $color-error;
      }
    }

    .icon-button {
      padding: 0 20px;
      background-color: transparent;
    }

    .error-msg {
      font-family: 'Slalom Sans';
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      color: $color-error;
      margin: 4px 0;
    }

    .visibility-icon {
      // this CSS changes the icon to the color $color-lt-grey
      filter: invert(33%) sepia(0%) saturate(102%) hue-rotate(277deg)
        brightness(112%) contrast(89%);

      &.focus {
        // this CSS changes the icon to the color $color-primary
        filter: invert(85%) sepia(27%) saturate(4250%) hue-rotate(139deg)
          brightness(97%) contrast(96%);
      }

      &.error {
        // this CSS changes the icon to the color $color-error
        filter: invert(12%) sepia(72%) saturate(6719%) hue-rotate(1deg)
          brightness(64%) contrast(112%);
      }
    }

    .done-icon.MuiSvgIcon-root {
      color: $color-success;
      height: 18px;
      width: 18px;
    }
  }
}
