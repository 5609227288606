@import '../../css/color.scss';

.fun-fact-container {
  padding: 220px 120px;
  display: flex;
  justify-content: center;

  .fun-fact {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 78px;

    .value {
      margin-bottom: 16px;
      margin-top: 0;
    }

    .title {
      color: $color-dk-grey;
      margin: 0;
    }

    &:not(:last-child) {
      margin-right: 48px;
    }
  }

  @media (max-width: 1300px) {
    flex-direction: column;

    .fun-fact:not(:last-child) {
      margin-bottom: 48px;
      margin-right: 0;
    }
  }
}
