@import '../../../css/color.scss';

.container {
  text-align: center;
  width: 342px;

  .content-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .title {
    margin: 12px 0px 0px;
    color: $color-black;
    text-align: center;
  }

  .text {
    margin: 2px 0px 0px;
    color: $color-dk-grey;
  }

  .link {
    margin: 12px 0px 0px;
    width: 214px;
    height: 48px;
    font-weight: 700;
    color: $color-black;
    transition: text-decoration 0.3s ease;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
