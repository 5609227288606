@import '../../../css/color.scss';
@import '../../../pages/registration-page/registration-page.scss';

.contact-info {
  width: 100%;
  margin-bottom: 40px;

  .contact-title {
    color: $color-black;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .form-field {
    max-width: 449px;

    .tooltip-icon {
      color: $color-dk-grey;
    }
  }

  .input-field {
    max-width: 416px;
    &.has-input .MuiInputLabel-root {
      font-family: 'Slalom Sans';
      transition: none;
      transform: translate(12px, -6px) scale(0.75);
      &.Mui-focused {
        color: $color-secondary;
        transform: translate(12px, -6px) scale(0.75);
      }
    }

    &:not(.has-input) .MuiInputLabel-root {
      font-family: 'Slalom Sans';
      transform: translate(16px, 16px) scale(1);

      &.Mui-focused {
        color: $color-secondary;
        transform: translate(12px, -6px) scale(0.75);
      }

      &:not(.Mui-focused) {
        transform: translate(16px, 16px);
      }
    }
  }

  .email {
    .MuiOutlinedInput-input {
      font-family: 'Slalom Sans';
    }

    .MuiOutlinedInput-root {
      height: 50px;
    }

    .MuiInputLabel-root {
      font-family: 'Slalom Sans';

      &.Mui-focused {
        color: $color-secondary;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 0;
      inset: 0;
    }

    .Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: $color-primary !important;
      }
    }

    &.invalid {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 2px solid $color-error;
      }

      label {
        color: $color-error;
      }
    }
  }

  .tooltip-icon {
    margin-left: 15px;
  }
}
