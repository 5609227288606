@import '../../../css/color.scss';
@import '../../../css/font.scss';

.label.MuiFormLabel-root.MuiInputLabel-root {
  font-family: 'Slalom Sans';
  letter-spacing: normal;
  font-weight: 400;
  width: 100%;
  color: $color-md-grey;

  &:not(.selected) {
    transform: translate(13px, 12px);
  }
  &.selected {
    font-size: 12px;
    transform: translate(13px, -9px);
    transition: none;
  }

  &.Mui-focused:not(.blurred) {
    color: $color-secondary;
    font-family: 'Slalom Sans';
    font-weight: 400;
    transform: translate(13px, -9px);
    font-size: 12px;
  }
  &.Mui-focused.blurred {
    color: $color-md-grey;
  }
  &:hover {
    cursor: pointer;
  }
  &.Mui-disabled {
    color: $color-lt-grey;
  }

  .MuiFormControl-root {
    display: flex;
    width: 100%;
  }
}

.item.MuiMenuItem-root {
  font-family: 'Slalom Sans';
  color: $color-black;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: $color-white;
  height: 40px;
  width: 100%;

  &.Mui-selected {
    background-color: $color-white;
    font-weight: 700;
  }
  &.MuiMenuItem-root:hover {
    background-color: $color-ul-grey;
  }
  &.MuiMenuItem-root:focus {
    background-color: $color-white;
    &:hover {
      background-color: $color-ul-grey;
    }
  }
}

.dropdown.MuiFormControl-root {
  display: flex;
  width: 256px;
}

.menu-container {
  display: flex;
  width: 100%;
  max-width: 256px;
}

.pronoun-select.MuiInputBase-root.MuiOutlinedInput-root {
  font-family: 'Slalom Sans';
  display: flex;
  width: 100%;
  height: 50px;
  border-width: 1px;
  border-radius: 0;
  border-color: $color-lt-grey;
  align-items: center;
  &.Mui-focused.blurred.selected {
    fieldset.MuiOutlinedInput-notchedOutline {
      legend {
        max-width: 100%;
      }
    }
  }
  &.selected {
    fieldset.MuiOutlinedInput-notchedOutline legend {
      max-width: 100%;
    }
  }
  &:hover {
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid $color-black;
    }
  }

  &.Mui-focused:not(.blurred) {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: $color-primary;
      font-family: 'Slalom Sans';
      legend {
        max-width: 100%;
        transform: translate(13px, -9px);
        transition: none;
      }
    }
    .MuiSvgIcon-root {
      transform: none;
      color: $color-primary;
    }
  }

  &.Mui-focused.blurred {
    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
    &.hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border: 1px solid $color-black;
      }
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border: 1px solid $color-lt-grey;
      legend {
        max-width: 0%;
      }
    }
  }

  &.Mui-disabled {
    background-color: $color-ul-grey;
    pointer-events: none;
  }

  .MuiSvgIcon-root {
    color: $color-md-grey;
    margin-right: 10px;
    path {
      transform: scale(0.85);
      transform-origin: 12px 12px;
    }
    transform-origin: 12px 12px;
    transform: rotate(180deg);
    transition: transform 300ms ease-in;
  }
  .MuiSelect-select {
    padding: 13px 0px 13px 18px;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  border-radius: 0;
  margin-top: 4px;
  border: 1px solid $color-lt-grey;
  box-shadow: 0px 2px 8px rgba(9, 9, 28, 0.06);
}

.MuiList-root.MuiMenu-list {
  padding: 0px;
}
