@import '../../css/color.scss';

.FeatureCardContainer {
  max-width: 366px;

  .title {
    text-align: left;
    margin: 24px 0px 16px 0px;
  }

  .text {
    color: $color-dk-grey;
    text-align: left;
    margin-right: 33px;
  }
}
