@import '../../../css/color.scss';

.card-container {
  background-color: $color-white;
  padding: 100px 197px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;

  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
