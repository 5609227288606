@import '../../css/color.scss';
@import '../../css/font.scss';

.videos-container {
  width: 100%;
  background-image: url('./V1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px rgba($color-black, 0.64);

  .items-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $color-white;
    padding: 22.3% 8.4% 0% 8.4%;

    .text-container {
      display: flex;
      flex-direction: column;

      .date {
        @include p-eyebrow-text;
      }

      .title-text {
        border-left: 4px solid $color-primary;
        margin: 8px 0 16px;
        padding-left: 8px;
        width: 491px;
      }

      .play-button-container {
        display: flex;
        flex-direction: row;
        justify-self: center;

        .MuiSvgIcon-root {
          width: 48px;
          height: 48px;
          color: $color-lt-grey;
          cursor: pointer;
        }

        .background-timestamp {
          padding-left: 8px;
          padding-top: 12px;
        }
      }
    }
    .video-list {
      display: flex;
      justify-content: center;
    }
    .more-videos-button {
      margin-top: 64px;
      margin-bottom: 64px;
    }
  }
}
