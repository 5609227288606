@import '../../../css/font.scss';
@import '../../../css/color.scss';

.outlined_button {
  @include button-text;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid;
  line-height: 22px;
  box-sizing: border-box;
  background-color: transparent;
  padding: 12px 29px;
  transition: background-color 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &.outlined_light {
    border-color: $color-white;
    color: $color-white;

    .right-icon {
      filter: invert(100%);
    }

    &:hover {
      background-color: $color-white;
      color: $color-black;

      .right-icon {
        filter: none;
      }
    }

    &:disabled {
      border-color: $color-md-grey;
      color: $color-md-grey;

      .right-icon {
        @include black-to-md-grey;
      }
    }
  }

  &.outlined_dark {
    border-color: $color-black;
    color: $color-black;

    &:hover {
      background-color: $color-black;
      color: $color-white;

      .right-icon {
        filter: invert(100%);
      }
    }

    &:disabled {
      border-color: $color-lt-grey;
      color: $color-lt-grey;

      .right-icon {
        @include black-to-lt-grey;
      }
    }
  }

  &.icon-only {
    padding: 8px;

    .icon-box {
      width: 32px;
      height: 32px;
      display: grid;
      justify-content: center;
      align-items: center;

      .right-icon {
        margin: 0;
      }
    }
  }
  .right-icon {
    margin-left: 12px;
    height: 12px;
  }

  &:disabled {
    pointer-events: none;
  }
}
