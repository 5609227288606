@import '../../css/color.scss';

.footer {
  max-height: 552px;
  width: 100%;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-black;
    color: $color-white;
    align-items: flex-start;
    padding: 6% 10%;
    text-align: left;
    gap: 32px;

    .links {
      text-decoration-line: underline;
      display: flex;
      flex-wrap: wrap;
      row-gap: 16px;

      .link {
        color: $color-primary;
        padding-right: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .socials {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5px 0px;

      .icon-button {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;

        .social-icon {
          color: $color-white;
          width: 24px;
          height: 24px;
          margin-right: 16px;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    p {
      margin: 0;
    }

    .log-out:hover {
      cursor: pointer;
    }
  }
}
